import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import SharedProviders_0_0 from './extensions/f372f52e3d44920e5f3e59a954ccb9ed9d6c013e6ee22f678b051f65756c413b/providers';
import SharedProviders_1_0 from './extensions/van-base-ui/providers';
import SharedProviders_2_0 from './extensions/material-ui/providers';


@NgModule({
    imports: [CommonModule, ],
    providers: [...SharedProviders_0_0, ...SharedProviders_1_0, ...SharedProviders_2_0],
})
export class SharedExtensionsModule {}
