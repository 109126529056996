import {
  addNavMenuItem,
} from '@vendure/admin-ui/core';

export default [
  addNavMenuItem(
    {
      id: 'van-bases',
      label: 'Van bases',
      routerLink: ['/extensions/van-bases'],
      // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
      icon: 'form',
    },
    'catalog',
  ),
];
